/**

**/


/*--------------------------------------------------------------
# Set main reusable colors and fonts using CSS variables
# Learn more about CSS variables at https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
--------------------------------------------------------------*/
/* Fonts */
:root {
  --font-default: "Sansfrancisco","sans-serif","Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  
  --font-primary:  "Sansfrancisco","sans-serif","Montserrat";
  --font-secondary:  "Sansfrancisco","sans-serif","Raleway";
}




/* Smooth scroll behavior */
:root {
  scroll-behavior: smooth;
}
/* Colors */
/* Smooth scroll behavior */
:root {
  scroll-behavior: smooth;
}
/* Colors */
:root {
  --color-default: #101011;
  --color-primary: #151923;
  --color-secondary: #050C9C;
  
  --primary: #0f1114;
  --primary-dark: #050C9C;
  --primary-light: #d8e4ff;
  
  --accent: #ff69b4;
  --accent-dark: #ff1493;
  --accent-light: #ffe6f2;
  
  --success: #00d27a;
  --success-dark: #009f5a;
  --success-light: #e6f9f0;
  
  --secondary: #8f15d5;
  --secondary-dark: #6013c7;
  --secondary-light: #f4e5fa;
  
  --info: #15b2d5;
  --info-dark: #0f8ca8;
  --info-light: #e0f5fa;
  
  --caution: #dbbb25;
  --caution-dark: #d58f15;
  --caution-light: #fbf9e4;
  
  --error: #e6185e;
  --error-dark: #bf1257;
  --error-light: #fce4eb;
  
  --black: #1d1d1d;
  --dark-1: #2d2d2d;
  --dark-2: #4d4d4d;
  --dark-3: #121111;
  
  --gray-1: #8d8d8d;
  --gray-2: #adadad;
  --gray-3: #cdcdcd;
  --gray-4: #e0e0e0;
  
  --light-1: #efefef;
  --light-2: #f5f5f5;
  --light-3: #fafafa;
  
  --white: #ffffff;
  --color-white: #ffffff;

  --gradient-1: linear-gradient(180deg, #1A2130 0%, #ff69b4 100%);
  --gradient-2: linear-gradient(180deg, #1A2130 13.02%, #050C9C 85.42%);
  --gradient-3: linear-gradient(180deg, #050C9C 0%, #8f15d5 100%);
  --gradient-4: linear-gradient(180deg, #1A2130 0%, #00d27a 100%);
  --gradient-5: linear-gradient(180deg, #1A2130 0%, #15bbd5 100%);
  --gradient-6: linear-gradient(180deg, #1A2130 0%, #dbbb25 100%);
  --gradient-7: linear-gradient(180deg, #1A2130 0%, #e6185e 100%);
  --gradient-8: linear-gradient(180deg, #1A2130 0%, #ff69b4 100%);
  --gradient-9: linear-gradient(180deg, #1A2130 13.02%, #8a1d65 85.42%);
  
  --shadow-1: 0px 0px 1px rgba(40, 41, 61, 0.08),
              0px 0.5px 2px rgba(96, 97, 112, 0.16);
  --shadow-2: 0px 0px 1px rgba(40, 41, 61, 0.04),
              0px 2px 4px rgba(96, 97, 112, 0.16);
  --shadow-3: 0px 0px 2px rgba(40, 41, 61, 0.04),
              0px 4px 8px rgba(96, 97, 112, 0.16);
  --shadow-4: 0px 2px 4px rgba(40, 41, 61, 0.04),
              0px 8px 16px rgba(96, 97, 112, 0.16);
  --shadow-5: 0px 2px 8px rgba(40, 41, 61, 0.04),
              0px 16px 24px rgba(96, 97, 112, 0.16);
  --shadow-6: 0px 2px 8px rgba(40, 41, 61, 0.08),
              0px 20px 32px rgba(96, 97, 112, 0.24);
}
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: var(--font-default);
  color: var(--color-default);
}

a {
  color: var(--color-primary);
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  color: #82cbed;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-primary);
  color: var(--black)
}

p{color: var(--dark-3)}

.pointer {cursor:pointer}

.error { 
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

/*--------------------------------------------------------------
# Sections & Section Header
--------------------------------------------------------------*/
section {
  overflow: hidden;
  padding: 80px 0;
}

.section-header {
  text-align: center;
  padding-bottom: 30px;
}

.section-header h2 {
  /*font-size: 32px;*/
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-header h2:after {
  content: "";
  position: absolute;
  display: block;
  width: 60px;
  height: 2px;
  background: var(--color-primary);
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.section-header p {
  margin-bottom: 0;
}

  .light-bg{ background:#f5f5f5;}
  
/* ===== Buttons Css ===== */
.primary-btn {
  background: var(--primary);
  color: var(--white);
  box-shadow: var(--shadow-2);
}
.active.primary-btn, .primary-btn:hover, .primary-btn:focus {
  background: var(--primary-dark);
  color: var(--white);
  box-shadow: var(--shadow-4);
}
.deactive.primary-btn {
  background: var(--gray-4);
  color: var(--dark-3);
  pointer-events: none;
}

.primary-btn-outline {
  border-color: var(--primary);
  color: var(--primary);
}
.active.primary-btn-outline, .primary-btn-outline:hover, .primary-btn-outline:focus {
  background: var(--primary-dark);
  color: var(--white);
}
.deactive.primary-btn-outline {
  color: var(--dark-3);
  border-color: var(--gray-4);
  pointer-events: none;
}

.btn-get-started {
	font-family: var(--primary);    
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	padding: 12px 24px;
	border-radius: 4px;
	letter-spacing: 1px;
	display: inline-block;  
	transition: 0.5s;
	color: var(--color-white);
	background: var(--color-primary);
	border: 1px solid transparent;
}
/*--------------------------------------------------------------
# Login Page
--------------------------------------------------------------*/
.gradient-custom-2 {
  /* fallback for old browsers */
  background: #fccb90;
  
  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);
  
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);
  }
  
  @media (min-width: 768px) {
  .gradient-form {
  height: 100vh !important;
  }
  }
  @media (min-width: 769px) {
  .gradient-custom-2 {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem;
  }
  }


/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 140px 0 60px 0;
  min-height: 30vh;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.breadcrumbs:before {
  content: "";
  background-color: rgba(126, 134, 150, 0.9);  
  position: absolute;
  inset: 0;
}

.breadcrumbs h2 {
  font-size: 56px;
  font-weight: 500;
  color: #fff;
  font-family: var(--font-secondary);
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.558);
}

.breadcrumbs ol a {
  color: rgba(255, 255, 255, 1);
  transition: 0.3s;
}

.breadcrumbs ol a:hover {
  text-decoration: underline;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #fff;
  content: "/";
}

/*--------------------------------------------------------------
# Scroll top button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: var(--color-primary);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.scroll-top:hover {
  background: rgba(34, 67, 120, 0.8);
  color: #fff;
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  inset: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
  transition: all 0.6s ease-out;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid var(--color-primary);
  border-top-color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  transition: all 0.5s;
  z-index: 997;
  padding: 24px 0;
}

@media (max-width: 1200px) {
  .header {
    padding: 12px 0;
  }
}

.header.sticked {
  background: var(--primary);
  padding: 12px 0;
}

.header .logo img {
  /*max-height: 40px;*/
  margin-right: 6px;
  height: 60px;
  width: 240px;
}

.header .logo h1 {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  font-family: var(--font-secondary);
  margin: 0;
}

/*--------------------------------------------------------------
# Desktop Navigation
--------------------------------------------------------------*/
@media (min-width: 1280px) {
  .navbar {
    padding: 0;
  }

  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navbar li {
    position: relative;
  }

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 25px;
    font-family: var(--font-default);
    font-size: 1em;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
    white-space: nowrap;
    transition: 0.3s;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: #fff;
  }

  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.2s;
  }

  .navbar .dropdown ul li {
    min-width: 200px;
  }

  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    color: var(--color-secondary);
  }

  .navbar .dropdown ul a i {
    font-size: 12px;
  }

  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover>a {
    color: var(--color-primary);
  }

  .navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }

  .navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
}

/*--------------------------------------------------------------
# Login and Signup Button
--------------------------------------------------------------*/
.nav-btn {
  background-color: #de4795; 
  border: none;
  padding: 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  display: inline-block;
  text-align: center;
  margin: 10px;
}

.nav-btn:hover {
  background-color: #f595ba; /* Gold color for hover effect */
}

.nav-btn .active {
  font-weight: bold;
  text-decoration: underline;
}

.nav-btn a {
  text-decoration: none;
  color: #ffffff; /* White color for contrast */
  font-size: 16px;
  display: block;
}

.nav-btn a:hover {
  color: #000000; /* Black color on hover for contrast */
}

/* Responsive Design */
@media (max-width: 768px) {
  .nav-btn {
      padding: 8px 16px;
  }

  .nav-btn a {
      font-size: 14px;
  }
}

@media (max-width: 480px) {
  .nav-btn {
      padding: 6px 12px;
  }

  .nav-btn a {
      font-size: 12px;
  }
}


@media (max-width: 480px) {
  .nav-btn {
      padding: 6px 12px;
  }

  .nav-btn link {
      font-size: 12px;
  }
}



@media (min-width: 1280px) and (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

@media (min-width: 1280px) {

  .mobile-nav-show,
  .mobile-nav-hide {
    display: none;
  }
}


/*--------------------------------------------------------------
# Login and Signup Page
--------------------------------------------------------------*/

/* src/Auth.css */
.auth-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.auth-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.auth-container form div {
  margin-bottom: 15px;
}

.auth-container label {
  display: block;
  margin-bottom: 5px;
}

.auth-container input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.auth-container button {
  width: 100%;
  padding: 10px;
  background-color: #881f5a;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.auth-container button:hover {
  background-color: #a52970;
}

.auth-container p {
  color: red;
  text-align: center;
}









/*--------------------------------------------------------------
# Mobile Navigation
--------------------------------------------------------------*/
@media (max-width: 1279px) {
  .navbar {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    max-width: 400px;
    bottom: 0;
    transition: 0.3s;
    z-index: 9997;
  }

  .navbar ul {
    position: absolute;
    inset: 0;
    padding: 50px 0 10px 0;
    margin: 0;
    background: rgba(29, 34, 40, 0.9);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
  }

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    font-family: var(--font-default);
    /*font-size: 15px;*/
    font-weight: 600;
    color: rgba(255, 255, 255, 0.7);
    white-space: nowrap;
    transition: 0.3s;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: #fff;
  }

  .navbar .dropdown ul,
  .navbar .dropdown .dropdown ul {
    position: static;
    display: none;
    padding: 10px 0;
    margin: 10px 20px;
    background-color: rgba(20, 21, 51, 0.6);
  }

  .navbar .dropdown>.dropdown-active,
  .navbar .dropdown .dropdown>.dropdown-active {
    display: block;
  }

  .mobile-nav-show {
    color: rgba(255, 255, 255, 0.9);
    font-size: 28px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
  }

  .mobile-nav-hide {
    color: rgba(255, 255, 255, 0.9);
    font-size: 32px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
    position: fixed;
    right: 15px;
    top: 35px;
    z-index: 9999;
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .navbar {
    right: 0;
  }

  .mobile-nav-active .navbar:before {
    content: "";
    position: fixed;
    inset: 0;
    background: rgba(26, 29, 39, 0.7);
    z-index: 9996;
  }
}


/*--------------------------------------------------------------
# Hero blockquote Section start
--------------------------------------------------------------*/
/* General blockquote styling */
.hero blockquote {
  padding-left: 20px;
  border-left: 2px solid rgba(255, 255, 255, .7);
  margin: 40px 0;
}

/* Mobile-specific styling */
@media (max-width: 768px) {
  .hero blockquote {
    display: none; /* Hide blockquote on mobile devices */
  }

  
}




/*--------------------------------------------------------------
# Hero blockquote Section end
--------------------------------------------------------------*/





/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  position: relative;
  padding: 200px 0 100px 0;
  overflow: hidden;
}

.hero .video-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.hero .shape {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 350px;
}

.hero .container {
  position: relative;
  z-index: 1;
}

@media (min-width: 1365px) {
  .hero {
    background-attachment: fixed;
  }
}

.hero h2 {
  font-size: 2.75em;
  line-height: 1.25;
  font-weight: 700;
  color: var(--white);
  text-shadow: 0px 3px 8px #00000017;
  text-transform: capitalize;
}

.hero p {
  color: var(--white);
  opacity: 0.7;
}

.hero .btn-get-started {
  font-family: var(--primary);
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  padding: 12px 24px;
  border-radius: 4px;
  letter-spacing: 1px;
  display: inline-block;
  transition: 0.5s;
  color: var(--color-primary);
  background: var(--color-white);
  border: 1px solid transparent;
  z-index: 1000;
}

.hero .btn-get-started:hover {
  background: transparent;
  color: var(--white);
  border-color: var(--white);
}

.hero .btn-watch-video {
  z-index: 1000;
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  color: var(--white);
  font-weight: 600;
}

.hero .btn-watch-video i {
  color: var(--color-white);
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

.hero .btn-watch-video:hover {
  color: #fff;
}

.hero .btn-watch-video:hover i {
  color: #fff;
}

.hero .header-image img {
  width: 100%;
  border-radius: 20px 20px 0px 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .hero .header-image {
    display: none;
  }

  .hero .shape {
    height: 100px;
  }
}

@media (max-width: 640px) {
  .hero h2 {
    font-size: 30px;
    line-height: 42px;
  }

  .hero {
    padding: 300px 0 120px 0;
  }

  .hero .header-image {
    margin-top: 40px;
  }

  .hero .btn-get-started,
  .hero .btn-watch-video {
    font-size: 13px;
  }
}

.carousel-indicators [data-bs-target] {  
   background-color: #1f242e;
   }


.carousel-indicators {   
    bottom: -25px;
}
	/* Mobile-specific styling to increase the height of .hero */
@media (max-width: 768px) {
  .hero {
    padding: 200px 0 130px 0; /* Increase padding to increase height */
  }
}

/*--------------------------------------------------------------
# hero section end
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Why Choose Us Section
--------------------------------------------------------------*/
.why-us {
  padding-bottom: 0;
}

.why-us .img-bg {
  min-height: 500px;
  background-size: cover;
}

.why-us .slides {
  background-color: #f7f9fc;
}

.why-us h3 {
  font-size: 24px;
  font-weight: 700;
  color: var(--color-secondary);
}

.why-us h4 {
  font-size: 18px;
  font-weight: 400;
  color: #3e464e;
  font-family: var(--font-secondary);
}

.why-us .swiper {
  margin: 140px 140px 120px 140px;
  overflow: hidden;
}

.why-us .swiper-button-prev:after,
.why-us .swiper-button-next:after {
  font-size: 24px;
  color: var(--color-secondary);
}

.why-us .swiper-button-prev {
  left: 80px;
}

.why-us .swiper-button-next {
  right: 80px;
}

.why-us .swiper-pagination {
  margin-top: 30px;
  position: relative;
}

.why-us .swiper-pagination .swiper-pagination-bullet {
  background-color: var(--color-secondary);
}

.why-us .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}

@media (max-width: 1200px) {
  .why-us .swiper {
    margin: 60px 60px 40px 60px;
  }

  .why-us .swiper-button-prev,
  .why-us .swiper-button-next {
    display: none;
  }
}

@media (max-width: 575px) {
  .why-us .swiper {
    margin: 40px 40px 20px 40px;
  }
}

.page-about .why-us {
  padding: 0 0 80px 0;
}

/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 700;
  font-size: 36px;
  font-family: var(--font-secondary);
  color: var(--color-secondary);
}

.about .content p {
  margin: 30px 0;
  color: #60666c;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding: 0 0 15px 26px;
  position: relative;
  font-size: 15px;
  font-weight: 600;
}

.about .content ul i {
  position: absolute;
  font-size: 20px;
  left: 0;
  top: -3px;
  color: var(--color-primary);
}

/*--------------------------------------------------------------
# Services List Section
--------------------------------------------------------------*/

/*.services-list {
	background:var(--white)
}*/

/*--------------------------------------------------------------
# Services gradient
--------------------------------------------------------------*/
.gradient-1 {
  background: linear-gradient(135deg, #faf8f5, #555553);
  border-radius: 25px;
  color: black;
}

.gradient-2 {
  background: linear-gradient(135deg, #faf8f5, #555553);
  border-radius: 25px;
  color: black;
}

.gradient-3 {
  background: linear-gradient(135deg, #faf8f5, #555553);
  border-radius: 25px;
  color: black;
}

.gradient-4 {
  background: linear-gradient(135deg,#faf8f5, #555553);
  border-radius: 25px;
  color: black;
}

.card {
  padding: 20px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  width: 80%;
}

.card:hover {
  transform: translateY(-10px);
}

.btn-get-started {
  margin-top: 10px;
  background: #111112;
  color: white;
  border-radius: 5px;
  padding: 10px 20px;
  text-decoration: none;
}

.stretched-link {
  position: relative;
}

.flex-shrink-0 {
  margin-bottom: 15px;
}

/*--------------------------------------------------------------
blog component slider start
--------------------------------------------------------------*/


/*--------------------------------------------------------------
# blog component slider end
--------------------------------------------------------------*/
/* Blog.css */
#main {
  padding: 20px 0;
}

@media (max-width: 768px) {
  #main {
      padding: 10px 0;
  }
}

/*--------------------------------------------------------------
# heroslider component start
--------------------------------------------------------------*/
/* HeroSlider.css */
.slider-img {
  opacity: 0.3; /* Adjust opacity for better text visibility */
  height: 500px;
  object-fit: cover;
}

.carousel-caption h3 {
  color: black;
  font-size: 35px;
  font-weight: 900;
}

.carousel-caption p {
  color: black;
  font-size: 20px;
  font-weight: 600px;
}

@media (max-width: 768px) {
  .slider-img {
      height: 300px;
  }

  .carousel-caption h3, .carousel-caption p {
      font-size: 20px;
    font-weight: 300;
  }
}

@media (max-width: 576px) {
  .slider-img {
      height: 200px;
  }

  .carousel-caption h3, .carousel-caption p {
      font-size: 12px;
  }
}

/*--------------------------------------------------------------
# heroslider component end
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# posts component start
--------------------------------------------------------------*/


.card {
  width: 100%; /* Make card width responsive */
  margin: auto;
}
.card-img-container {
  width: 100%;
  height: 200px; /* You can adjust the height as needed */
  overflow: hidden;
}

.card-img-fixed {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card .btn-black {
  background-color: black !important;
  color: white !important;
  border-color: black !important;
}

.card .btn-black:hover {
  background-color: darkgray !important;
  border-color: darkgray !important;
  color: white !important;
}


@media (min-width: 768px) {
  .card {
      max-width: 450px; /* Increase the maximum width of cards for larger screens */
  }
}

@media (min-width: 992px) {
  .card {
      max-width: 400px; /* Adjust the maximum width of cards for even larger screens */
  }
}


/*--------------------------------------------------------------
# posts component  end
--------------------------------------------------------------*/

/* Keyframes for scale and rotate animation */
@keyframes scaleRotate {
  0% {
    transform: scale(0.95) rotate(0deg);
    opacity: 0;
  }
  50% {
    transform: scale(1.05) rotate(5deg);
    opacity: 0.7;
  }
  100% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
}

/* Base styles for the single service card */
.services-list .single-service {
  position: relative;
  text-align: left;
  padding: 40px;
  box-shadow: var(--shadow-2);
  border-radius: 30px;
  border: 1px solid var(--gray-4);
  background-color: var(--white);
  overflow: hidden;
  opacity: 0; /* Initially hidden for animation */
  transform: scale(0.95) rotate(0deg); /* Initially scaled down */
  transition: all 0.5s ease-in-out; /* Increased duration for smoother transition */
}

/* Background images for each card */
.services-list .single-service.gradient-1 {
  background: url('https://images.pexels.com/photos/1181244/pexels-photo-1181244.jpeg') no-repeat center center;
  background-size: cover;
  color: #ffffff; /* Ensuring text contrast */
}

.services-list .single-service.gradient-2 {
  background: url('https://images.pexels.com/photos/669610/pexels-photo-669610.jpeg') no-repeat center center;
  background-size: cover;
  color: #ffffff; /* Ensuring text contrast */
}

.services-list .single-service.gradient-3 {
  background: url('https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg') no-repeat center center;
  background-size: cover;
  color: #ffffff; /* Ensuring text contrast */
}

.services-list .single-service.gradient-4 {
  background: url('https://images.pexels.com/photos/256219/pexels-photo-256219.jpeg') no-repeat center center;
  background-size: cover;
  color: #ffffff; /* Ensuring text contrast */
}

/* Overlay to darken the background image */
.services-list .single-service::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Adjust the opacity as needed */
  z-index: 1;
}

.services-list .single-service > * {
  position: relative;
  z-index: 2;
}

/* Animated class to be added when in view */
.services-list .single-service.animate {
  opacity: 1;
  animation: scaleRotate 0.8s ease-out; /* Increased duration for smoother animation */
}

/* Ensure that once animated, the card remains in its final state */
.services-list .single-service.animated {
  opacity: 1;
  transform: scale(1) rotate(0deg);
}

/* Additional styles for section header and service items */
.section-header p {
  font-size: 18px;
  margin-bottom: 25px;
}

.services-list .service-item {
  position: relative;
}

.services-list .service-item .icon i {
  font-size: 40px;
  line-height: 0;
  margin-right: 20px;
}

.services-list .service-item .title {
  font-weight: 700;
  margin: 8px 0;
  font-size: 24px;
}

.services-list .service-item .title a {
  color: var(--color-white);
}

.services-list .service-item .title a:hover {
  color: var(--color-primary);
}
/* for service page description*/
.services-list .service-item .description {
  line-height: 1.5;
  font-size: 1rem;
  color: black;
}
/* for service list description*/
.services-list .service-item .description-list {
  line-height: 1.5;
  font-size: 1rem;
  color: white;
}

.services-list .single-service .serial {
  font-size: 45px;
  display: block;
  margin-bottom: 7px;
  font-weight: 800;
  transition: all 0.3s ease-out;
}

.services-list .single-service:hover .serial {
  color: var(--primary);
}

.services-list .single-service h3 {
  color: var(--white);
  font-weight: 600;
}

.services-list .single-service p {
  color: var(--light-gray);
}

.services-list .light-rounded-buttons {
  margin-top: 25px;
}

/* Responsive styles for different screen sizes */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .services-list .single-service {
    padding: 40px;
  }
}

@media (max-width: 767px) {
  .services-list .single-service {
    padding: 35px;
    transition: all 0.7s ease-in-out; /* Increased duration for mobile devices */
  }
}


/*--------------------------------------------------------------
# Call To Action Section
--------------------------------------------------------------*/
.facts {
 background: linear-gradient(rgba(146, 154, 170, 0.8), rgb(36, 38, 53)), url("./img/cta-bg1.jpg") center center;
  background-size: cover;
  padding: 100px 0; 
}

@media (min-width: 1365px) {
  .facts {
    background-attachment: fixed;
  }
}

/*.call-to-action h3 {
  color: #fff;
  font-size: 28px;
  margin-bottom: 25px;
  font-weight: 700;  
}*/
.facts h3 {
	color: #fff;
	/*font-size: 28px;*/
	margin-bottom: 6px;
	font-weight: 400;
}

.facts p {
  color: #cadef0;
  margin-bottom: 25px;
  font-size:18px;
  
}


#facts .section-header h2:after {    
background: var(--color-white);}   


.facts .cta-btn {
  font-family: var(--font-default);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  color: #fff;
  background: #252937;
}

.facts .cta-btn:hover {
  background: var(--color-primary);
}

.facts .container {
  position: relative;
  z-index: 10;
}

.facts .counters span { 
  font-weight: 800;
  font-size: 68px;
  display: block;
  color: #fff;
}

.facts .counters p {
  padding: 0;
  margin: 0 0 10px 0;
  font-size: 18px;
  font-weight:400;
  color: #cdd6df;
}

.facts .facts-img {
  text-align: center;
  padding-top: 20px;
}

.facts .fct-title {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
}
/*--------------------------------------------------------------
# Features Section
--------------------------------------------------------------*/
.features {
  padding-bottom: 0;
  background: var(--light-2);
}

.features h3 {
  color: var(--color-secondary);
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 30px;
}

.features .icon-list i {
  margin-right: 10px;
  font-size: 24px;
  line-height: 1.2;
}

.features .icon-list span {
  font-size: 18px;
  color: #9597af;
}

.features .phone-wrap {
  position: absolute;
  right: 0;
}

@media (max-width: 768px) {
  .features .phone-wrap {
    position: relative;
  }
}

.features .phone-wrap img {
  min-width: 340px;
}

@media (max-width: 992px) {
  .features .phone-wrap img {
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .features .phone-wrap img {
    width: 100%;
  }
  .features .details {  
   padding: 0px 0px 50px 0px !important; 
}
}

.features .details {
  margin-top: 0px;
  /*padding: 120px 0; */
   padding: 0px 0px 100px 0px; 
}

.features .details h2 { 
  font-weight: 600;
  margin-bottom: 20px;
}

.features .details p {
  margin-bottom: 20px;
  font-size: 15px;
}


.features .details .btn-get-started {
  font-family: var(--primary);    
  font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    padding: 12px 24px;
    border-radius: 4px;
  letter-spacing: 1px;
  display: inline-block;  
  transition: 0.5s;
  color: var(--color-white);
  background: var(--color-primary);
  border: 1px solid transparent;

}




.features .details .btn-get-started:hover {
	background: transparent;
	color: var(--primary);
	border-color: var(--color-primary);

}


/*--------------------------------------------------------------
# Recent Blog Posts Section
--------------------------------------------------------------*/
.recent-posts .post-box {
  transition: 0.3s;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

.recent-posts .post-box .post-img {
  overflow: hidden;
  position: relative;
}

.recent-posts .post-box .post-img img {
  transition: 0.5s;
}

.recent-posts .post-box .meta {
  margin-top: 15px;
}

.recent-posts .post-box .meta .post-date {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-primary);
}

.recent-posts .post-box .meta .post-author {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-secondary);
}

.recent-posts .post-box .post-title {
  font-size: 18px;
  color: var(--color-secondary);
  font-weight: 700;
  margin: 15px 0 0 0;
  position: relative;
  transition: 0.3s;
}

.recent-posts .post-box p {
  margin: 15px 0 0 0;
  color: rgba(41, 42, 54, 0.7);
}

.recent-posts .post-box .readmore {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 1;
  transition: 0.3s;
  margin-top: 15px;
}

.recent-posts .post-box .readmore i {
  line-height: 0;
  margin-left: 4px;
  font-size: 18px;
}

.recent-posts .post-box:hover .post-title {
  color: var(--color-primary);
}

.recent-posts .post-box:hover .post-img img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Team Section
--------------------------------------------------------------*/
.team .team-member {
  overflow: hidden;
  background: #fff;
}

.team .team-member .member-img {
  position: relative;
  overflow: hidden;
}

.team .team-member .social {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 40px;
  opacity: 0;
  transition: ease-in-out 0.3s;
  background: rgba(255, 255, 255, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
}

.team .team-member .social a {
  transition: color 0.3s;
  color: #31507c;
  margin: 0 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.team .team-member .social a i {
  line-height: 0;
}

.team .team-member .social a:hover {
  color: var(--color-primary);
}

.team .team-member .social i {
  font-size: 18px;
  margin: 0 2px;
}

.team .team-member .member-info {
  padding: 25px 15px 0 15px;
  text-align: center;
}

.team .team-member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: var(--color-secondary);
}

.team .team-member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #6c757d;
}

.team .team-member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #6c757d;
}

.team .team-member:hover .social {
  opacity: 1;
}

/*--------------------------------------------------------------
# Services Cards Section
--------------------------------------------------------------*/
.services-cards {
  background: #f7f9fc;
}

.services-cards .card-item {
  border: 1px solid rgba(43, 48, 61, 0.1);
  background: #fff;
  position: relative;
  border-radius: 0;
}

.services-cards .card-item .card-bg {
  min-height: 270px;
  background-size: cover;
  background-repeat: no-repeat;
}

.services-cards .card-item .card-body {
  padding: 30px;
}

.services-cards .card-item h4 {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 15px;
  color: var(--color-secondary);
}

.services-cards .card-item p {
  color: var(--color-secondary);
  margin: 0;
}



/*--------------------------------------------------------------
# Cource Details
--------------------------------------------------------------*/
.course-details h3 {
  font-size: 24px;
  margin: 30px 0 15px 0;
  font-weight: 700;
  position: relative;
  padding-bottom: 10px;
}

.course-details h3:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  background: #eef0ef;
  bottom: 0;
  left: 0;
}

.course-details h3:after {
  content: "";
  position: absolute;
  display: block;
  width: 60px;
  height: 1px;
  background: #5fcf80;
  bottom: 0;
  left: 0;
}

.course-details .course-info {
  background: #f6f7f6;
  padding: 10px 15px;
  margin-bottom: 15px;
}

.course-details .course-info h5 {
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  font-family: "Poppins", sans-serif;
}

.course-details .course-info p {
  margin: 0;
  font-weight: 600;
}

.course-details .course-info a {
  color: #657a6d;
}

/*--------------------------------------------------------------
# Cource Details Tabs
--------------------------------------------------------------*/
.cource-details-tabs {
  overflow: hidden;
  padding-top: 0;
}

.cource-details-tabs .nav-tabs {
  border: 0;
}

.cource-details-tabs .nav-link {
  border: 0;
  padding: 12px 15px 12px 0;
  transition: 0.3s;
  color: #37423b;
  border-radius: 0;
  border-right: 2px solid #e2e7e4;
  font-weight: 600;
  font-size: 15px;
}

.cource-details-tabs .nav-link:hover {
  color: #5fcf80;
}

.cource-details-tabs .nav-link.active {
  color: #5fcf80;
  border-color: #5fcf80;
}

.cource-details-tabs .tab-pane.active {
  -webkit-animation: fadeIn 0.5s ease-out;
  animation: fadeIn 0.5s ease-out;
}

.cource-details-tabs .details h3 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #37423b;
}

.cource-details-tabs .details p {
  color: #777777;
}

.cource-details-tabs .details p:last-child {
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .cource-details-tabs .nav-link {
    border: 0;
    padding: 15px;
  }

  .cource-details-tabs .nav-link.active {
    color: #fff;
    background: #5fcf80;
  }
}

/*--------------------------------------------------------------
# Testimonials Section
--------------------------------------------------------------*/
.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px;
  margin: 40px 30px;
  box-shadow: 0px 0 20px rgba(27, 47, 69, 0.1);
  background: #fff;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  text-align: center;
  transition: 0.3s;
}

.testimonials .testimonial-item .stars {
  margin-bottom: 15px;
}

.testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50%;
  border: 4px solid #fff;
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: #e8eff6;
  opacity: 1;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}

.testimonials .swiper-slide {
  opacity: 0.3;
}

@media (max-width: 1199px) {
  .testimonials .swiper-slide-active {
    opacity: 1;
  }

  .testimonials .swiper-pagination {
    margin-top: 0;
  }

  .testimonials .testimonial-item {
    margin: 40px 20px;
  }
}

@media (min-width: 1200px) {
  .testimonials .swiper-slide-next {
    opacity: 1;
    transform: scale(1.12);
  }
}

/*--------------------------------------------------------------
# Portfolio Section
--------------------------------------------------------------*/
.portfolio .portfolio-flters {
  padding: 0;
  margin: 0 auto 30px auto;
  list-style: none;
  text-align: center;
}

.portfolio .portfolio-flters li {
  color: var(--color-secondary);
  cursor: pointer;
  display: inline-block;
  padding: 0;
  font-size: 18px;
  font-weight: 400;
  margin: 0 10px;
  line-height: 1;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.portfolio .portfolio-flters li:hover,
.portfolio .portfolio-flters li.filter-active {
  color: var(--color-primary);
}

.portfolio .portfolio-flters li:first-child {
  margin-left: 0;
}

.portfolio .portfolio-flters li:last-child {
  margin-right: 0;
}

@media (max-width: 575px) {
  .portfolio .portfolio-flters li {
    font-size: 14px;
    margin: 0 5px;
  }
}

.portfolio .portfolio-item {
  position: relative;
}

.portfolio .portfolio-item .portfolio-info {
  opacity: 0;
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px;
}

.portfolio .portfolio-item .portfolio-info h4 {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-default);
  padding-right: 50px;
}

.portfolio .portfolio-item .portfolio-info p {
  color: #6c757d;
  font-size: 14px;
  margin-bottom: 0;
  padding-right: 50px;
}

.portfolio .portfolio-item .portfolio-info .preview-link,
.portfolio .portfolio-item .portfolio-info .details-link {
  position: absolute;
  right: 50px;
  font-size: 24px;
  top: calc(50% - 14px);
  color: rgba(58, 65, 72, 0.7);
  transition: 0.3s;
  line-height: 0;
}

.portfolio .portfolio-item .portfolio-info .preview-link:hover,
.portfolio .portfolio-item .portfolio-info .details-link:hover {
  color: var(--color-primary);
}

.portfolio .portfolio-item .portfolio-info .details-link {
  right: 14px;
  font-size: 28px;
}

.portfolio .portfolio-item:hover .portfolio-info {
  opacity: 1;
  bottom: 20px;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid var(--color-primary);
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(27, 47, 69, 0.1);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d6e2ef;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
  margin-top: 10px;
}

/*.portfolio-details .portfolio-description {
 *padding-top: 30px;*
}*/

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--color-secondary);
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact {
 background: #f5f5f5 url("./img/contact-bg.png") left top no-repeat;
  background-size: contain;
  position: relative;
  
  
}

@media (max-width: 640px) {
  .contact {
    background-position: center 50px;
  }
}

.contact:before {
  content: "";
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.contact .info-item+.info-item {
  margin-top: 40px;
}

.contact .info-item i {
  font-size: 20px;
  background: var(--color-primary);
  color: #fff;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  margin-right: 15px;
}

.contact .info-item h4 {
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #99adb6;
}

.contact .info-item p {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.contact .php-email-form {
  width: 100%;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #df1529;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #059652;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #059652;
  border-top-color: #fff;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  padding: 12px 15px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: var(--color-primary);
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}


.contact .php-email-form button[type=submit] {
  font-family: var(--primary);    
  font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    padding: 12px 24px;
    border-radius: 4px;
  letter-spacing: 1px;
  display: inline-block;  
  transition: 0.5s;
  color: var(--color-white);
  background: var(--color-primary);
  border: 1px solid transparent;

}

.contact .php-email-form button[type=submit]:hover {
	background: transparent;
	color: var(--primary);
	border-color: var(--color-primary);

}


@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Blog Stylings
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Blog Home Posts List
/*--------------------------------------------------------------
# Career Page
--------------------------------------------------------------*/
.blog .blog-details {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.blog .blog-details .post-img {
  margin: -30px -30px 20px -30px;
  overflow: hidden;
}

.blog .blog-details .title {
  font-size: 28px;
  font-weight: 700;
  padding: 0;
  margin: 20px 0 0 0;
  color: var(--color-secondary);
}

.blog .blog-details .content {
  margin-top: 20px;
}

.blog .blog-details .content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog .blog-details .content blockquote {
  overflow: hidden;
  background-color: rgba(46, 54, 62, 0.06);
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog .blog-details .content blockquote p {
  color: var(--color-default);
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog .blog-details .content blockquote:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: var(--color-secondary);
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog .blog-details .meta-top {
  margin-top: 20px;
  color: var(--color-gray);
}

.blog .blog-details .meta-top ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog .blog-details .meta-top ul li+li {
  padding-left: 20px;
}

.blog .blog-details .meta-top i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
  color: rgba(210, 223, 229, 0.8);
}

.blog .blog-details .meta-top a {
  color: var(--color-gray);
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog .blog-details .meta-bottom {
  padding-top: 10px;
  border-top: 1px solid rgba(54, 58, 64, 0.15);
}

.blog .blog-details .meta-bottom i {
  color: #38618e;
  display: inline;
}

.blog .blog-details .meta-bottom a {
  color: rgba(27, 47, 69, 0.8);
  transition: 0.3s;
}

.blog .blog-details .meta-bottom a:hover {
  color: var(--color-primary);
}

.blog .blog-details .meta-bottom .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog .blog-details .meta-bottom .cats li {
  display: inline-block;
}

.blog .blog-details .meta-bottom .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog .blog-details .meta-bottom .tags li {
  display: inline-block;
}

.blog .blog-details .meta-bottom .tags li+li::before {
  padding-right: 6px;
  color: var(--color-default);
  content: ",";
}

.blog .blog-details .meta-bottom .share {
  font-size: 16px;
}

.blog .blog-details .meta-bottom .share i {
  padding-left: 5px;
}

.blog .post-author {
  padding: 20px;
  margin-top: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .post-author img {
  max-width: 120px;
  margin-right: 20px;
}

.blog .post-author h4 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding: 0;
  color: var(--color-secondary);
}

.blog .post-author .social-links {
  margin: 0 10px 10px 0;
}

.blog .post-author .social-links a {
  color: rgba(27, 47, 69, 0.5);
  margin-right: 5px;
}

.blog .post-author p {
  font-style: italic;
  color: rgba(var(--color-gray-rgb), 0.8);
  margin-bottom: 0;
}

.blog .posts-list article {
  height: 100%;
  border-bottom: 1px solid #d6e2ef;
  padding-bottom: 30px;
}

.blog .posts-list article+article {
  margin-top: 60px;
}

.blog .posts-list .post-img {
  max-height: 240px;
  overflow: hidden;
}

.blog .posts-list .title {
  font-size: 20px;
  font-weight: 600;
  padding: 0;
  margin: 20px 0 0 0;
}

.blog .posts-list .title a {
  color: var(--color-secondary);
  transition: 0.3s;
}

.blog .posts-list .title a:hover {
  color: var(--color-primary);
}

.blog .posts-list .meta-top {
  margin-top: 20px;
  color: #95a5b5;
}

.blog .posts-list .meta-top ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog .posts-list .meta-top ul li+li {
  padding-left: 20px;
}

.blog .posts-list .meta-top i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
  color: rgba(198, 217, 226, 0.8);
}

.blog .posts-list .meta-top a {
  color: #9fabb7;
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog .posts-list .content {
  margin-top: 20px;
}

.blog .posts-list .read-more a {
  display: inline-block;
  color: #1f98d1;
  transition: 0.3s;
  font-size: 15px;
  font-weight: 500;
}

.blog .posts-list .read-more a:hover {
  color: var(--color-primary);
}



/*--------------------------------------------------------------
#.management
--------------------------------------------------------------*/
.management {
  background: var(--light-2);
}

.management .member {
  position: relative;
  box-shadow: 0px 2px 15px rgba(44, 73, 100, 0.08);
  padding: 20px;
  border-radius: 10px;
  background:#fff;  
}
 
@media screen and (min-width: 768px) {
  
  
}


@media screen and (max-width: 768px) {
  .management .member {
  margin:5px;
}
.management .member .member-info {
  padding-left: 0px !important;
}
.management .member {
    padding: 25px  10px!important;
  }
  
  
}

.management .member .pic {
  overflow: hidden;
  width: 150px;
  border-radius: 50%;
}

.management .member .pic img {
  transition: ease-in-out 0.3s;
}

.management .member:hover img {
  transform: scale(1.1);
}

.management .member .member-info {
  padding-left: 30px;
}

.management .member h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  color: #565f68;
}

.management .member span {
  display: block;
  font-size: 15px;
  padding-bottom: 10px;
  position: relative;
  font-weight: 500;
}

.management .member span::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: var(--color-primary);
  bottom: 0;
  left: 0;
}

.management .member p {
  margin: 10px 0 0 0;
  font-size: 14px;
}

.management .member .social {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.management .member .social a {
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  background: var(--color-primary);
}

.management .member .social a i {
  color: #fff;
  font-size: 16px;
  margin: 0 2px;
}

.management .member .social a:hover {
  background: #a0afbc;
}

.management .member .social a+a {
  margin-left: 8px;
}


/*About us*/
.vision .card-body {padding-bottom:2rem}
.vision .card-body .card-title{margin-bottom:15px;}


.vision .card-body .card-title {
  display: block;  
  padding-bottom: 10px;
  position: relative;
  font-weight: 500;
}

.vision .card-body .card-title::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 2px;
  background: var(--color-primary);
  bottom: 0;
  left: 0;
}

/*--------------------------------------------------------------
# Blog Sidebar
--------------------------------------------------------------*/
.blog .sidebar .sidebar-title {
  font-size: 22px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  color: var(--color-secondary);
}

.blog .sidebar .sidebar-item+.sidebar-item {
  margin-top: 40px;
}

.blog .sidebar .search-form form {
  background: #fff;
  border: 1px solid rgba(53, 61, 69, 0.2);
  padding: 3px 10px;
  position: relative;
}

.blog .sidebar .search-form form input[type=text] {
  border: 0;
  padding: 4px;
  border-radius: 4px;
  width: calc(100% - 40px);
}

.blog .sidebar .search-form form input[type=text]:focus {
  outline: none;
}

.blog .sidebar .search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  background: var(--color-primary);
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  line-height: 0;
}

.blog .sidebar .search-form form button i {
  line-height: 0;
}

.blog .sidebar .search-form form button:hover {
  background: rgba(189, 212, 222, 0.8);
}

.blog .sidebar .categories ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .categories ul li+li {
  padding-top: 10px;
}

.blog .sidebar .categories ul a {
  color: var(--color-secondary);
  font-size: 15px;
}

.blog .sidebar .categories ul a:hover {
  color: var(--color-default);
}

.blog .sidebar .categories ul a span {
  padding-left: 5px;
  color: rgba(var(--color-default-rgb), 0.4);
  font-size: 14px;
}

.blog .sidebar .recent-posts .post-item {
  display: flex;
  box-shadow: 0px 0 15px rgba(0, 0, 0, 0.08);
  padding: 20px;
}

.blog .sidebar .recent-posts .post-item+.post-item {
  margin-top: 15px;
}

.blog .sidebar .recent-posts img {
  width: 80px;
  margin-right: 15px;
}

.blog .sidebar .recent-posts h4 {
  font-size: 18px;
  font-weight: 400;
}

.blog .sidebar .recent-posts h4 a {
  color: var(--color-secondary);
  transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
  color: var(--color-primary);
}

.blog .sidebar .recent-posts time {
  display: block;
  font-style: italic;
  font-size: 14px;
  color: rgba(var(--color-default-rgb), 0.4);
}

.blog .sidebar .tags {
  margin-bottom: -10px;
}

.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .tags ul li {
  display: inline-block;
}

.blog .sidebar .tags ul a {
  color: #7a8590;
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid rgba(27, 47, 69, 0.15);
  display: inline-block;
  border-radius: 50px;
  transition: 0.3s;
}

.blog .sidebar .tags ul a:hover {
  color: #fff;
  border: 1px solid var(--color-primary);
  background: var(--color-primary);
}

.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: rgba(27, 47, 69, 0.8);
  font-size: 14px;
}

/*--------------------------------------------------------------
# Blog Comments
--------------------------------------------------------------*/
.blog .comments {
  margin-top: 30px;
}

.blog .comments .comments-count {
  font-weight: bold;
}

.blog .comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog .comments .comment .comment-img {
  margin-right: 14px;
}

.blog .comments .comment .comment-img img {
  width: 60px;
}

.blog .comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}

.blog .comments .comment h5 a {
  font-weight: bold;
  color: var(--color-default);
  transition: 0.3s;
}

.blog .comments .comment h5 a:hover {
  color: var(--color-primary);
}

.blog .comments .comment h5 .reply {
  padding-left: 10px;
  color: var(--color-secondary);
}

.blog .comments .comment h5 .reply i {
  font-size: 20px;
}

.blog .comments .comment time {
  display: block;
  font-size: 14px;
  color: rgba(27, 47, 69, 0.8);
  margin-bottom: 5px;
}

.blog .comments .comment.comment-reply {
  padding-left: 40px;
}

.blog .comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.blog .comments .reply-form p {
  font-size: 14px;
}

.blog .comments .reply-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .comments .reply-form input:focus {
  box-shadow: none;
  border-color: rgba(86, 184, 230, 0.8);
}

.blog .comments .reply-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: rgba(86, 184, 230, 0.8);
}

.blog .comments .reply-form .form-group {
  margin-bottom: 25px;
}

.blog .comments .reply-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: var(--color-secondary);
}

.blog .comments .reply-form .btn-primary:hover {
  background-color: rgba(27, 47, 69, 0.8);
}

/*--------------------------------------------------------------
# Blog Home Pagination
--------------------------------------------------------------*/
.blog .blog-pagination {
  margin-top: 30px;
  color: #38618e;
}

.blog .blog-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog .blog-pagination li {
  margin: 0 5px;
  transition: 0.3s;
}

.blog .blog-pagination li a {
  color: #2aa5df;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: #dbf0fa;
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
  background: var(--color-primary);
  color: #fff;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  padding-top: 80px;
}

.counts .count-box {
  padding: 30px 30px 25px 30px;
  width: 100%;
  position: relative;
  text-align: center;
  box-shadow: 0px 2px 35px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.counts .count-box i {
  position: absolute;
  width: 54px;
  height: 54px;
  top: -27px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  background: #fff;
  color: #e03a3c;
  border-radius: 50px;
  border: 2px solid #fff;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.counts .count-box span {
  font-size: 36px;
  display: block;
  font-weight: 700;
  color: #111111;
}

.counts .count-box p {
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Clients Section
--------------------------------------------------------------*/
.clients {
  padding: 0 0 60px 0;
  /*background: var(--light-2);*/
   background: var(--white);
}

.clients .swiper-slide img {
  /*opacity: 0.5;*/
  transition: 0.3s;
  /*filter: grayscale(100); */

  background-color: var(--white);
padding:10px;  
  
  border-radius: 8px;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  border: 1px solid #eee;
}





.clients .swiper-slide img:hover {
   box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.096);
  -webkit-transform: translateY(-5px);
  -moz-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  transform: translateY(-5px);
}
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  font-size: 14px;
}

.footer .footer-content {
  background-color: var (--white);
  background-size: contain;
  padding: 60px 0 30px 0;
}

.footer .footer-content .footer-info {
  margin-bottom: 30px;
}

.footer .footer-content .footer-info .logo {
  line-height: 0;
  margin-bottom: 10px;
}

.footer .footer-content .footer-info .logo img {
  /*max-height: 40px;*/
   max-width: 140px;
  margin-right: 6px;
}

.footer .footer-content .footer-info .logo span {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  color: var(--color-secondary);
  font-family: var(--font-secondary);
  margin-top: 3px;
}

.footer .footer-content .footer-info p {
  font-size: 15px;
  margin-bottom: 0;
  font-family: var(--font-primary);
  color: black;
}

.footer .footer-content .social-links a {
  font-size: 14px;
  line-height: 0;
  display: inline-block;
  width: 32px;
  height: 32px;
  color: rgba(22, 26, 29, 0.5);
  margin-right: 10px;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary);
  color: #fff;
  border-radius: 50px;
}

.footer .footer-content .social-links a:hover {
  background-color: #79828b;
}

.footer .footer-content h4 {
  font-size: 16px;
  font-weight: bold;
  color: var(--black);
  position: relative;
  padding-bottom: 12px;
}

.footer .footer-content .footer-links {
  margin-bottom: 30px;
}

.footer .footer-content .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-content .footer-links ul i {
  margin-right: 2px;
  color: var(--color-primary);
  font-size: 16px;
  line-height: 0;
}

.footer .footer-content .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-content .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-content .footer-links ul a {
  color: var(--dark-3);
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

.footer .footer-content .footer-links ul a:hover {
  color: var(--color-primary);
}

.footer .footer-content .footer-contact p {
  line-height: 26px;
  color: var(--dark-3);
}

.footer .footer-legal {
 background: var(--light-2);
  padding: 30px 0;
}

.footer .footer-legal .copyright {
  text-align: center;
  color:  var(--dark-3);
}

.footer .footer-legal .credits {
  padding-top: 4px;
  text-align: center;
  font-size: 13px;
  color:  var(--dark-3);
}